<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Hamper Items</h1>
            </div>
        </div>

        <div class="row ">

            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body">

                        <div>
                            <button type="button" class="btn btn-default float-left mb-3" @click="AddBox"><i class="ion-plus"></i> Add</button>
                        </div>

                     
                        <div class="dropdown">
                            <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {{mapZones[ZoneId].Name}}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li class="dropdown-item"
                                    v-for="item in mapZones" :key="item.Id"
                                    @click="doViewByFilter(item.Id,FilterProductTypeId)">
                                    <a href="javascript:;">{{item.Name}}</a>
                                </li>

                            </ul>

                            <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                {{FilterProductTypeId==0?'== All Types ==':mapProductTypes[FilterProductTypeId].Description}}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li class="dropdown-item"
                                    v-for="item in mapProductTypes" :key="item.Id"
                                    @click="doViewByFilter(ZoneId,item.Id)">
                                    <a href="javascript:;">{{item.Id!=0?item.Description:'== All Types =='}}</a>
                                </li>
                            </ul>

                            <button class="btn btn-primary" type="button" @click="$router.push('/HamperItemPDF')">
                                PDF Page
                            </button>
                        </div>

                        <div class="mt-3">
                            <table class="table table-hover table-bordered" id="datatable">
                                <thead>
                                    <tr>

                                        <th>Code</th>
                                        <th>Name</th>
                                        <th>ProductType</th>
                                        <th> </th>
                                        
                                        <th>Price (No VAT)</th>
                                        <th>Size (w-h) cm</th>
                                        <th>Updated By</th>
                                        <th>Created At</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in dataByFilter" :key="item.Code" :style="{'background-color':item.Stock<=0?'#ff000021 !important':'inherit'}">
                                        <td @click="EditDetail(item)" v-if="checkFullAcl('HamperItem/Edit')">
                                            <a href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip" data-bs-placement="top"
                                               title="Edit">
                                                {{item.Code}}
                                            </a>
                                        </td>
                                        <td @click="EditDetail(item)" v-if="checkFullAcl('HamperItem/Edit')">
                                            <a href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip" data-bs-placement="top"
                                               title="Edit">
                                                {{item.Name}}
                                            </a>
                                        </td>
                                        <td class="">
                                            <select class="select mb-1" :id="'ProductTypeId_'+item.Id" @change="ChangeType(item)">
                                                <option v-for="prodtype in ProductTypes" :key="prodtype.Id" :value="prodtype.Id" :selected="item.ProductTypeId==prodtype.Id?'true':null">{{prodtype.Description}}</option>
                                            </select>
                                        </td>

                                        <td @click="EditDetail(item)" v-if="checkFullAcl('HamperItem/Edit')">

                                            <img :src="item.HamperImage" style="max-height:100px;max-width:100px" v-if="item.HamperImage!=''" />

                                        </td>

                                        <!--<td>{{formatMoney(item.ProductTypeId!=undefined?item.Price*mapProductTypes[item.ProductTypeId].MultiValue:item.Price)}}</td>-->
                                        <td>{{formatMoney(item.Price)}}</td>
                                        <td>{{item.HamperSizeX}}-{{item.HamperSizeY}}</td>
                                        <td>{{item.UpdatedBy}}</td>
                                        <td>{{formatDate(item.Created)}}</td>
                                        <td v-show="checkAcl('Edit')">
                                            <button type="button" class="btn btn-danger btn-sm" @click="Remove(item)" title="Edit">
                                                <i class="ion-trash-a p-1"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>



        <MDBModal id="editHamperItemModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showHamperItem"
                  size="lg">
            <MDBModalHeader>
                <MDBModalTitle v-html="editTitle"></MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>

                <MDBRow>
                    <MDBCol md="3">
                        <MDBInput label="Name"
                                  id="ItemName"
                                  v-model="editData.Name"
                                  invalidFeedback="Please provide a image"
                                  validFeedback="Looks good!" />
                    </MDBCol>
                    <MDBCol md="2">
                        <MDBInput label="Code"
                                  id="ItemCode"
                                  v-model="editData.Code"
                                  invalidFeedback="Please provide a image"
                                  validFeedback="Looks good!" />
                    </MDBCol>
                    <MDBCol md="2">
                        <MDBInput label="Width (cm)"
                                  id="HamperSizeX"
                                  v-model="editData.HamperSizeX"
                                  type="number"
                                  step="1"
                                  invalidFeedback="Please provide a image"
                                  validFeedback="Looks good!" />
                    </MDBCol>
                    <MDBCol md="2">
                        <MDBInput label="Height  (cm)"
                                  id="HamperSizeY"
                                  v-model="editData.HamperSizeY"
                                  type="number"
                                  step="1"
                                  invalidFeedback="Please provide a image"
                                  validFeedback="Looks good!" />
                    </MDBCol>
                </MDBRow>
                <MDBRow class="mt-3">
                    <MDBCol md="4">                        
                            <MDBInput label="Original Price (No VAT)"
                                      id="Price"
                                      type="number"
                                      step="1000"
                                      v-model="editData.Price"
                                      invalidFeedback="Please provide a image"
                                      validFeedback="Looks good!" />
                        </MDBCol>
                </MDBRow>
                <MDBRow class="mt-3">
                    <MDBCol md="2">
                        Select Zone:
                    </MDBCol>
                    <MDBCol md="3">
                        <div class="dropdown">
                            <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                {{mapZones[editZoneId].Name}}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li class="dropdown-item"
                                    v-for="item in mapZones" :key="'editItemZone'+item.Id"
                                    v-show="item.Id!=0"
                                    @click="selectEditZone(item.Id)">
                                    <a href="javascript:;">{{item.Name}}</a>
                                </li>

                            </ul>
                        </div>
                    </MDBCol>

                </MDBRow>
                <MDBRow class="mt-3">
                    <MDBCol md="12">
                        <button type="button" class="btn btn-primary btn-with-icon" @click="SelectFileUpload(event)"><i class="ion-image"></i>Select Box Image</button>
                        <div class="mt-3" id="uploadFileInfo" style="text-align:center">
                            <img :src="editData.HamperImage" style="max-height:500px;max-width:500px" v-if="editData.HamperImage!=''" />
                        </div>
                    </MDBCol>
                </MDBRow>
                <!--<MDBRow>
        <MDBCol md="12">
            <button type="button" class="btn btn-primary btn-with-icon" @click="SelectFilesUpload(event)"><i class="ion-images"></i>Select Many Images</button>
            <div class="mt-3" id="uploadFilesInfo">

            </div>
        </MDBCol>
    </MDBRow>-->


            </MDBModalBody>
            <MDBModalFooter>
                <button type="submit" class="btn btn-success" @click="SaveEdit">
                    Save
                </button>
                <button type="button" class="btn btn-danger" @click="showHamperItem=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>


    </div>
</template>
<style>
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        color: white !important;
    }
    .editable-click, a.editable-click{
        border-bottom:none;
    }
</style>
<script>
    //https://therichpost.com/vue-3-datatable-with-export-buttons-print-csv-copy-with-dynamic-data/
    //Datatable Modules
    import "datatables.net-dt/js/dataTables.dataTables"
    import "datatables.net-dt/css/jquery.dataTables.min.css"
    //import "datatables.net-buttons/js/dataTables.buttons.js"
    //import "datatables.net-buttons/js/buttons.colVis.js"
    //import "datatables.net-buttons/js/buttons.flash.js"
    //import "datatables.net-buttons/js/buttons.html5.js"
    //import "datatables.net-buttons/js/buttons.print.js"
    import $ from 'jquery';
    import 'datatables.net-responsive'


    import {
        //MDBBtn,
        MDBModal,
        MDBModalBody,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalFooter,
        MDBCol, MDBRow,
        MDBInput
    } from 'mdb-vue-ui-kit';


    import c from '@/mylib/common'
    import { mapState, mapActions } from 'vuex'



    export default {
        name: `HamperItem`,
        components: {
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBModalFooter,
            MDBCol, MDBRow,
            MDBInput,
        },
        data: function () {
            return {
                editTitle: '',
                editData: {},
                data: [],
                dataByFilter: [],
                editZoneId: 0,
                ZoneId: 0,
                FilterProductTypeId: 0,
                mapZones: { 0: { 'Id': 0, 'Name': '-- All Regions --' } },
            ProductTypes: [],
                mapProductTypes: {},
                FileUpload: {},
                dataTable: null,
                showHamperItem: false,
                NameExisted: {},
            }
        },

        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
            timeOffset: state => state.session.timeOffset,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'setGroups': 'user/setGroups',

                'call': 'api/call'
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes(this.$options.name + "/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.getAll();

            },
            formatDate(dstr, notime) {
                if (dstr == null || dstr == "") return 'N/A';

                try {
                    var d = new Date(dstr);
                    d.setTime(d.getTime() - this.timeOffset * 60 * 1000);
                    return c.formatDate(new Date(d), notime);
                } catch (ex) {
                    console.log(ex);
                    return 'N/A';
                }
            },
            formatMoney(money, symbol, decCount) {
                symbol = symbol || '';
                decCount = decCount || 0;

                const rs = c.formatMoney(money, symbol, decCount);
                return rs;
            },
            validName() {
                return this.validTextField(this.editData.Name, 'ItemName', true, /.*/, '', 2, 100, this.NameExisted, this.editData.Id);
            },
            validCode() {
            return this.validTextField(this.editData.Code, 'ItemCode', true, /^[a-zA-z0-9]+$/, 'Please input character and number only!', 2, 10);
            },
            validPrice() {
                return this.validNumField(this.editData.Price, "Price", 1000);
            },
            validSizeX() {
                return this.validNumField(this.editData.HamperSizeX, "HamperSizeX", 1);
            },
            validSizeY() {
                return this.validNumField(this.editData.HamperSizeY, "HamperSizeY", 1);
            },
            validNumField(value, id, minVal, maxVal) {
                if (!id || id == '') return;
                let error = "";
                if (isNaN(value))
                    error = "Please input number.";
                else if (minVal != undefined && value < minVal)
                    error = "Must greater than or equal " + minVal;
                else if (maxVal != undefined && value > maxVal)
                    error = "Must less than or equal " + maxVal;
                //return value
                if (error == '') {
                    if ($('#' + id).length > 0) {
                        $('#' + id).removeClass('is-invalid');
                        $('#' + id).addClass('is-valid');
                    }
                    return true;
                } else {
                    if ($('#' + id).length > 0) {
                        $('#' + id + '~.invalid-feedback')[0].innerHTML = error;
                        $('#' + id).addClass('is-invalid');
                        $('#' + id).removeClass('is-valid');
                    }
                    $('#' + id).focus();
                    return false;
                }
            },
            validTextField(value, id, required, regex, regexMessage, minlen, maxlen, NameExisted, editedId) {
                if (!id || id == '') return;
                let error = "";
                minlen = minlen || 0;
                maxlen = maxlen || 1000;
                editedId = editedId || 0;
                const checkedvalue = value.trim();
                const isCheckRequire = required || (!required && checkedvalue.length > 0);
                
                if (required && checkedvalue == '') {
                    error = "Please input this field!";
                }
                //check name existed
                else if (NameExisted != null && NameExisted != undefined &&
                    (NameExisted[checkedvalue] != undefined && editedId != NameExisted[checkedvalue] || (Array.isArray(NameExisted) && NameExisted.includes(checkedvalue)))
                ) {
                    error = id + " is duplicated!";
                }
                //check pattern
                else if (isCheckRequire && regex != null && regex != undefined && !regex.test(checkedvalue))
                    error = regexMessage || `Invalid ${id}!`;
                //check len

                else if (isCheckRequire && (checkedvalue.length < minlen)) {
                    error = `Please input at least ${minlen} characters!`;
                }
                else if (checkedvalue.length > maxlen) {

                    error = `Please do not exceed ${maxlen} characters!`;
                }
                //return value
                if (error == '') {
                    if ($('#' + id).length > 0) {
                        $('#' + id).removeClass('is-invalid');
                        $('#' + id).addClass('is-valid');
                    }
                    return true;
                } else {
                    if ($('#' + id).length > 0) {
                        $('#' + id + '~.invalid-feedback')[0].innerHTML = error;
                        $('#' + id).addClass('is-invalid');
                        $('#' + id).removeClass('is-valid');
                    }
                    $('#' + id).focus();
                    return false;
                }
            },
            AddBox() {
                if (!this.checkFullAcl('HamperItem/Edit')) return;
            this.editData = { Id: 0, Name: '', Code: '', HamperSizeX: '0', HamperSizeY:'0',Price:'0' };
                this.editTitle = 'Add new Box';
                this.showHamperItem = true;
            },
            EditDetail(item) {
                if (!this.checkFullAcl('HamperItem/Edit')) return;
                this.editData = {};
                this.FileUpload = {};
                this.editData = JSON.parse(JSON.stringify(item));                
                this.editTitle = item.Name;
                this.editZoneId = item.ZoneId;
                this.editData["HamperSizeX"] = this.editData["HamperSizeX"] + '';
                this.editData["HamperSizeY"] = this.editData["HamperSizeY"] + '';
                this.editData["Price"] = this.editData["Price"] + '';
                
                this.showHamperItem = true;
            },
            Remove(item) {
                this.showModal({
                    isShow: true, title: 'Warning', content: "Are you sure to delete Item <br /><b>" + item.Name + "</b>?", type: 'error', callback: () => {

                        this.DoRemove(item);
                    }, isConfirm: true
                });
            },
            async DoRemove(item) {
                this.loading(true);

                const rs = await this.call({
                    Action: 'HamperItem/Remove', Object: {
                        RemoveId: item.Id,
                    }
                })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    this.getAll();
                    this.showToast({ isShow: true, title: 'Success', content: "Remove " + item.Name + " successful.", type: 'success' });
                }
            },
            SelectFileUpload() {
                var el = window._protected_reference = document.createElement("INPUT");
                el.type = "file";
                //el.accept = ".jpg,.jpeg,.png";
                el.accept = "image/*";
                //el.multiple = "multiple"; // remove to have a single file selection

                // (cancel will not trigger 'change')
                var _this = this;
                el.addEventListener('change', function () {
                    // access el.files[] to do something with it (test its length!)

                    // add first image, if available
                    if (el.files.length) {
                        const fileSize = el.files[0].size / 1024 / 1024; // in MiB
                        if (fileSize > 2) {
                            _this.showToast({ isShow: true, title: '', content: "File size exceeds 2 MB", type: 'error' });
                            return;
                        }
                        _this.FileUpload = el.files[0];
                        //document.getElementById('uploadFileInfo').innerHTML = _this.FileUpload.name;
                        document.getElementById('uploadFileInfo').innerHTML = `<img style="max-height:500px;max-width:500px" src="${URL.createObjectURL(el.files[0])}" />`
                    }


                    //// test some async handling
                    //new Promise(function (resolve) {
                    //    setTimeout(function () { console.log(el.files); resolve(); }, 1000);
                    //})
                    //    .then(function () {
                    //        // clear / free reference
                    //        el = window._protected_reference = undefined;
                    //    });

                });
                el.click();
            },
            async SaveEdit() {
                let isValidImage = true;
                if (this.FileUpload == null && this.editData.Id==0) {
                    this.showModal({ isShow: true, title: 'Error', content: "Please select a Hamper Image for this product", type: 'error' });
                    isValidImage = false;
                }
                let isValidPrice = this.validPrice();
                let isValidSizeX = this.validSizeX();
                let isValidSizeY = this.validSizeY();
                let isValidName = this.validName();
                let isValidCode = this.validCode();
                let isValidForm = isValidPrice && isValidSizeX && isValidSizeY && isValidName && isValidCode;
                if (!isValidForm && isValidImage) {
                    this.showToast({ isShow: true, title: '', content: "Form is invalid!", type: 'error' });
                }
                if (!isValidForm || !isValidImage) return;

                this.loading(true);

                const rs = await this.call({
                    Action: 'HamperItem/Save', Object: {
                        Id: this.editData.Id,
                        Code: this.editData.Code,
                        Name: this.editData.Name,
                        HamperSizeX: this.editData.HamperSizeX,
                        HamperSizeY: this.editData.HamperSizeY,
                        Price: this.editData.Price,
                        ZoneId: this.editZoneId,
                    }, Files: this.FileUpload
                })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    this.getAll();
                    this.showHamperItem = false;
                }
            },
        async ChangeType(item) {
            var productTypeId = $('#ProductTypeId_' + item.Id).val();
            if (productTypeId == undefined || productTypeId == null || productTypeId == 0) {
                this.showModal({ isShow: true, title: 'Error', content: "Please select a Type this product", type: 'error' });
                return;
            }

            this.loading(true);

            const rs = await this.call({
                Action: 'HamperItem/SaveProductType', Object: {
                    Id: item.Id,
                    ProductTypeId: productTypeId
                }
            })
            this.loading(false);
            this.error = rs.Error;
            if (rs.Error != "") {
                //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                $('#ProductTypeId_' + item.Id).val(item.ProductTypeId == null ? 0 : item.ProductTypeId);
            } else {
                this.dataByFilter[item.Id].ProductTypeId = productTypeId;
            }
        },
            async getAll() {
                this.loading(true);
                
                const rs = await this.call({ Action: 'HamperItem/View', Object: { ZoneId: this.ZoneId } })

                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    this.loading(false);
                    //no permission
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }

                } else {
                    this.data = [];
                    Object.keys(rs.Data.items).forEach(id => {
                        this.data.push(rs.Data.items[id]);
                        if (this.NameExisted[rs.Data.items[id].Name] == undefined)
                            this.NameExisted[rs.Data.items[id].Name] = id;
                    });
                    this.mapZones = { 0: { 'Id': 0, 'Name': '-- All Region --' } };
                    for (let i = 0; i < rs.Data.Zones.length; i++) {
                        this.mapZones[rs.Data.Zones[i].Id] = rs.Data.Zones[i];
                    }
                    this.ProductTypes = rs.Data.ProductTypes;
                    Object.keys(this.ProductTypes).forEach(i => {
                        let item = this.ProductTypes[i];
                        this.mapProductTypes[item.Id] = item;
                    });
                    this.doViewByFilter(this.ZoneId,this.FilterProductTypeId,true);

                }
            },
            doViewByFilter(zoneId, typeId, isFirstLoad) {                
                if (isFirstLoad == undefined) isFirstLoad = false;
                if (this.dataTable != null) {
                    this.dataTable.destroy();
                }
                
                this.ZoneId = zoneId;                
                this.FilterProductTypeId = typeId;
                
                this.dataByFilter = {};               
                for (let i = 0; i < this.data.length; i++) {
                if ((this.data[i].ZoneId == zoneId || zoneId == 0) && (this.data[i].ProductTypeId == typeId || typeId == 0) )
                        this.dataByFilter[this.data[i].Id] = this.data[i];
                }
                setTimeout(this.doTable, isFirstLoad?300:200);
            },
            selectEditZone(zoneId) {
                this.editZoneId = zoneId;
            },
            doTable() {
                const _this = this;

                _this.dataTable = $('#datatable').DataTable({
                    responsive: true,
                    'columnDefs': [         // see https://datatables.net/reference/option/columns.searchable
                        {
                            'searchable': false,
                            'targets': [2],
                        },
                        // {
                        //     'targets': [3],
                        //     visible: false                                                  
                        // },
                    ]
                });

                this.loading(false);
            },

        },

        created() {

        },
        mounted() {
            //if already login then start,
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();



        },
        watch: {
            //catch status from not login to logged in
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                console.log("user index newVal", newVal);
                if (newVal) {
                    this.start();
                }
            },

        }
    };
</script>
